import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';

const Header = () => {
  return (
    <header>
      <div className="header-area">
        <div className="main-header">
          <div className="header-top d-none d-lg-block">
            <div className="header-left-social">
              <ul className="header-social">
                <li><a href="https://www.facebook.com/Airborne.Aviation.Academy.Gondia/"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.linkedin.com/company/airborne-academy-india/"><i className="fab fa-linkedin-in"></i></a></li>
                <li><a href="https://www.instagram.com/airborne.aviation.academy/"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
            <div className="container">
              <div className="col-xl-12">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="header-info-left">
                    <ul>
                      <li>connect@airborne.org.in</li>
                      <li>+91 830 864 8144</li>
                    </ul>
                  </div>
                  <div className="header-info-right">
                    <ul>
                      <li><a href="#"><i className="ti-user"></i>Login</a></li>
                      <li><a href="#"><i className="ti-lock"></i>Register</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bottom header-sticky">
            <div className="container">
              <div className="menu-wrapper d-flex justify-content-between align-items-center">
                <div className="logo">
                  <Link to="/"><img src="assets/img/logo/logo.png" alt="Logo" width={100} /></Link>
                </div>
                <div className="main-menu d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li><Link to="/">Home</Link></li>
                      <li><Link to="/about">About</Link></li>
                      <li><Link to="/courses">Courses</Link></li>
                      <li>
                        <Link to="/blog">Articles</Link>
                      </li>
                      <li><Link to="/contact">Contact</Link></li>
                    </ul>
                  </nav>
                </div>
                <div className="header-buttons d-none d-lg-flex align-items-center">
                  <button className="contact-us-button" onClick={() => window.open('https://calendly.com/airborne-academy-india/30min', '_blank')}>Schedule a Call</button>
                </div>
                <div className="mobile-menu-btn d-block d-lg-none">
                  <MobileNavbar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
