import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Courses from './pages/Courses';
import Instructors from './pages/Instructors';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Elements from './pages/Elements';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndCondition from './pages/TermsAndCondition';
import CareerPage from './pages/CareerPage';
import Support from './pages/Support';


const App = () => {
  return (
    <Router>
      <Header/>
      {/* <Navbar/> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/instructors" element={<Instructors />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/elements" element={<Elements />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path='/career' element={<CareerPage/>}/>
          <Route path='/support' element={<Support/>}/>
        </Routes>
        <Footer/>
    </Router>
  );
};

export default App;
