import React, { useState } from 'react';
import './SupportPage.css'; // Import the CSS file
import TitleComponent from '../components/TitleComponent';

const Support = () => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [faqs, setFaqs] = useState([
        {
            question: "What courses do you offer?",
            answer: "We offer a variety of aviation courses including pilot training, ground instruction, and more.",
            open: false
        },
        {
            question: "How can I apply for a course?",
            answer: "You can apply for a course by visiting our courses page and filling out the application form.",
            open: false
        },
        {
            question: "What are the admission requirements?",
            answer: "Admission requirements vary by course. Please refer to the specific course page for detailed requirements.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open;
            } else {
                faq.open = false;
            }
            return faq;
        }));
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!form.name) formErrors.name = 'Name is required';
        if (!form.email) {
            formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            formErrors.email = 'Email address is invalid';
        }
        if (!form.message) formErrors.message = 'Message is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            // Handle form submission (e.g., send data to backend or API)
            alert('Support request submitted');
            setForm({ name: '', email: '', message: '' });
            setErrors({});
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <>
        <TitleComponent title={"Support"}/>
        <div className="container">
            
            <section className="faq-section">
                <h2>Frequently Asked Questions</h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <h3 onClick={() => toggleFAQ(index)} className="faq-question">
                            {faq.question}
                        </h3>
                        {faq.open && <p className="faq-answer">{faq.answer}</p>}
                    </div>
                ))}
            </section>

            <section className="contact-section">
                <h2>Contact Information</h2>
                <p>If you have any questions or need further assistance, please contact us at:</p>
                <p>
                Airborne Aviation Academy <br />
                <h3>Airborne Aviation Academy</h3>
                <p>White House, Pal Chowk, <br/>Near Yewale Tea and CJ Company<br/>Railtoly, Gondia-441614</p>
            </p>
            </section>

            <section className="support-form-section">
                <h2>Submit a Support Request</h2>
                <form onSubmit={handleSubmit} className="support-form">
                    <div className="form-group">
                        <label htmlFor="name">Name:</label><br />
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={form.name} 
                            onChange={handleChange} 
                            required 
                        />
                        {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label><br />
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={form.email} 
                            onChange={handleChange} 
                            required 
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label><br />
                        <textarea 
                            id="message" 
                            name="message" 
                            value={form.message} 
                            onChange={handleChange} 
                            required 
                        ></textarea>
                        {errors.message && <p className="error">{errors.message}</p>}
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </section>
        </div>
        </>
    );
};

export default Support;
