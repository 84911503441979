import React from 'react';
import TitleComponent from '../components/TitleComponent';

const CareerPage = () => {
    return (
        <>
            <TitleComponent title={"Career"} />
            <div className='container' style={{ padding: "50px 0" }}>


                <section style={{ marginBottom: '40px' }}>
                    <h2>Join Our Team</h2>
                    <p>
                        At Airborne Aviation Academy, we are always looking for passionate and talented individuals to join our team. We offer a dynamic work environment, opportunities for growth, and the chance to be part of a leading aviation training academy.
                    </p>
                </section>

                <section style={{ marginBottom: '40px' }}>
                    <h2 style={{ textAlign: "center" }}>Current Openings</h2>
                    <JobOpening
                        title="Flight Instructor"
                        location="Gondia, Maharashtra"
                        description="We are seeking experienced Flight Instructors to join our team. The ideal candidate will have a CPL and instructional experience."
                        requirements={[
                            "Valid Commercial Pilot License (CPL)",
                            "Previous instructional experience",
                            "Strong communication skills",
                            "Ability to work in a team environment"
                        ]}
                    />
                    <JobOpening
                        title="Ground Instructor"
                        location="Gondia, Maharashtra"
                        description="We are looking for a knowledgeable Ground Instructor to provide quality ground training to our students."
                        requirements={[
                            "Experience in aviation education",
                            "Strong knowledge of aviation theory",
                            "Excellent teaching skills",
                            "Ability to work in a team environment"
                        ]}
                    />
                    <JobOpening
                        title="Administrative Assistant"
                        location="Gondia, Maharashtra"
                        description="We are seeking a detail-oriented Administrative Assistant to support our office operations."
                        requirements={[
                            "Strong organizational skills",
                            "Proficiency in Microsoft Office",
                            "Excellent communication skills",
                            "Ability to handle multiple tasks simultaneously"
                        ]}
                    />
                </section>

                <section>
                    <h2>How to Apply</h2>
                    <p>
                        To apply for any of the open positions, please send your resume and a cover letter to <a href="mailto:careers@airborne.org.in" style={{ color: 'green' }}>careers@airborne.org.in</a>. In your cover letter, please specify the position you are applying for and why you are a good fit for our team.
                    </p>
                </section>

                <section>
                    <h2>Why Work With Us?</h2>
                    <ul>
                        <li>Competitive salary and benefits</li>
                        <li>Opportunities for professional growth</li>
                        <li>Supportive and dynamic work environment</li>
                        <li>Access to advanced training and development</li>
                    </ul>
                </section>
            </div>
        </>
    );
};

const JobOpening = ({ title, location, description, requirements }) => (
    <div style={{ marginBottom: '30px' }}>
        <h3>{title}</h3>
        <p><strong>Location:</strong> {location}</p>
        <p>{description}</p>
        <h4>Requirements:</h4>
        <ul>
            {requirements.map((req, index) => <li key={index}>{req}</li>)}
        </ul>
    </div>
);

export default CareerPage;
