import React from 'react';
import PropTypes from 'prop-types';

const Testimonial = ( ) => {
    const testimonials = [
        {
            icon: "assets/img/reviews/aarushi.png",
            text: "I had a strong desire to excel in aviation sector Airborne has changed my personality completely and gave right shape to my carrier. And because of their hard work I am proud and employee of Indian Oil (Aviation)",
            rating: 5,
            name: "AARUSHI UKEY",
            title: "ASSISTANT TO GM, INDIAN OIL AVIATION"
        },
        {
            icon: "assets/img/reviews/manoranjan.png",
            text: "Airborne not only provided theoretical and practical knowledge but also groomed my overall Personality. It was amazing journey of learning with airborne.",
            rating: 5,
            name: "MANORANJAN PATHAK",
            title: "GROUND STAFF INDIGO"
        },
        {
            icon: "assets/img/reviews/simarpreet.png",
            text: "Airborne is like my family Institute, my sister first completed her course and started working as ground staff and I got inspired through her I am placed with Celebi as ground staff and many of my neighbours are also doing course with Airborne, Looking at my Success",
            rating: 5,
            name: "SIMARPREET GULATI",
            title: "GROUND STAFF CELEBI"
        }
    ];
    return (
        <div className="testimonial-area fix pt-180 pb-180 section-bg" style={{ backgroundImage: "url('assets/img/gallery/section_bg03.png')" }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-9 col-md-9">
                        <div id="testimonialCarousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {testimonials.map((testimonial, index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                        <div className="single-testimonial pt-65">
                                            <div className="testimonial-icon mb-45">
                                                <img src={testimonial.icon} className="ani-btn" alt="Testimonial" />
                                            </div>
                                            <div className="testimonial-caption text-center">
                                                <p>{testimonial.text}</p>
                                                <div className="testimonial-ratting">
                                                    {[...Array(testimonial.rating)].map((_, i) => (
                                                        <i className="fas fa-star" key={i}></i>
                                                    ))}
                                                </div>
                                                <div className="ratting-caption">
                                                    <span>{testimonial.name}<span> - {testimonial.title}</span> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <a className="carousel-control-prev" href="#testimonialCarousel" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#testimonialCarousel" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    testimonials: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
};

export default Testimonial;
