import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { NavbarLinks } from '../constant/NavbarLinks';
import { IoMdClose, IoMdMenu } from 'react-icons/io';

const MobileNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const location = useLocation();
  const [toggleMenuBtn, setToggleMenuBtn] = useState(false);

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname);
  };

  const handleMenuClick = () => {
    if (toggleMenuBtn) {
      onClose();
    } else {
      onOpen();
    }
    setToggleMenuBtn(!toggleMenuBtn);
  };

  return (
    <>
      <div ref={btnRef} onClick={handleMenuClick} className="mobile-menu-btn">
        <button>
          {
            toggleMenuBtn ? <IoMdClose style={{ fontSize: '20px', color: '#2D3092' }} /> : <IoMdMenu style={{ fontSize: '20px', color: '#2D3092' }} />
          }
        </button>
      </div>

      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent style={{ background: 'white' }}>
          <DrawerCloseButton />
          <DrawerHeader style={{ marginTop: '150px', color: '#2D3092' }}></DrawerHeader>
          <DrawerBody>
            <div>
              <ul className="mobile-menu-links">
                {
                  NavbarLinks.map((link) => (
                    <li
                      onClick={handleMenuClick}
                      key={link.title}
                      className={`${matchRoute(link?.path) ? "highlight-mobile-menu-link" : ""} mobile-menu-link`}
                    >
                      <Link to={link.path}>
                        {<link.icon />}
                        {link.title}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
