import React from 'react'

function Instructors() {
  return (
    <div>
      Instructor
    </div>
  )
}

export default Instructors
