import React from 'react'
import CoursesCard from '../components/CoursesCard'

function Courses() {
  return (
    <div>
      {/* Hero Start */}
      <div className="slider-area">
        <div className="slider-height2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Our Courses</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}


      {/* Popular Course Start */}
      <div className="popular-course section-padding30">
        <div className="container">

          <div className="row">
            {/* Single Course */}
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="single-course mb-40 ml-40 mr-40">
                <div className="course-img">
                  <img src="assets/img/gallery/airhostess-training.jpg" alt="Graphic Design" />
                </div>
                <div className="course-caption">
                  <div className="course-cap-top">
                    <h4><a href="#">Airhostess Training</a></h4>
                  </div>
                  <div className="course-cap-mid d-flex justify-content-between">
                    <div className="course-ratting">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <ul><li>52 Review</li></ul>
                  </div>
                  <div className="course-cap-bottom d-flex justify-content-between">
                    <ul>
                      <li><i className="ti-user"></i> 562</li>
                      <li><i className="ti-heart"></i> 562</li>
                    </ul>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Course */}
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="single-course mb-40 ml-40 mr-40">
                <div className="course-img">
                  <img src="assets/img/gallery/ground-crew-training.jpg" alt="Web Development" />
                </div>
                <div className="course-caption">
                  <div className="course-cap-top">
                    <h4><a href="#">Ground Cabin Crew Training</a></h4>
                  </div>
                  <div className="course-cap-mid d-flex justify-content-between">
                    <div className="course-ratting">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                    <ul><li>52 Review</li></ul>
                  </div>
                  <div className="course-cap-bottom d-flex justify-content-between">
                    <ul>
                      <li><i className="ti-user"></i> 562</li>
                      <li><i className="ti-heart"></i> 562</li>
                    </ul>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* Popular Course End */}

      <div className="about-details ">
        <div className="container">
          <CoursesCard />
        </div>
      </div>

    </div>
  )
}

export default Courses
