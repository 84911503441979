import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      {/* Footer Start */}
      <div className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top footer-padding">
            {/* Footer Heading */}
            <div className="footer-heading">
              <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-7 col-md-10">
                  <div className="footer-tittle2">
                    <h4>Stay Updated</h4>
                  </div>
                  {/* Form */}
                  <div className="footer-form mb-50">
                    <div id="mc_embed_signup">
                      <form
                        target="_blank"
                        action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                        method="get"
                        className="subscribe_form relative mail_part"
                        noValidate
                      >
                        <input
                          type="email"
                          name="EMAIL"
                          id="newsletter-form-email"
                          placeholder="Email Address"
                          className="placeholder hide-on-focus"
                          onFocus={(e) => (e.target.placeholder = '')}
                          onBlur={(e) => (e.target.placeholder = 'Your email address')}
                        />
                        <div className="form-icon">
                          <button type="submit" name="submit" id="newsletter-submit" className="email_icon newsletter-submit button-contactForm">
                            Subscribe Now
                          </button>
                        </div>
                        <div className="mt-10 info"></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="footer-tittle2">
                    <h4>Let’s Get Social</h4>
                  </div>
                  {/* Footer Social */}
                  <div className="footer-social">
                    <a href="https://www.facebook.com/Airborne.Aviation.Academy.Gondia/"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.linkedin.com/company/airborne-academy-india/"><i className="fab fa-linkedin"></i></a>
                    <a href="https://www.instagram.com/airborne.aviation.academy/"><i className="fab fa-instagram"></i></a>
                    <a href="#"><i className="fab fa-youtube"></i></a>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer Menu */}
            <div className="row d-flex justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>About Us</h4>
                    <ul>
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/career">Careers</Link></li>
                      <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      <li><Link to="/terms-and-condition">Terms and Condition</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Campus</h4>
                    <ul>
                      <li><Link to="/academic-solution">Academic Solutions</Link></li>
                      <li><Link to="/business-solution">Business Solutions</Link></li>
                      <li><Link to="/government-solution">Government Solutions</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Study</h4>
                    <ul>
                      <li><Link to="/admission-policy">Admissions Policy</Link></li>
                      <li><Link to="/getting-start">Getting Started</Link></li>
                      <li><Link to="/online-application">Online Application</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-50">
                  <div className="footer-tittle">
                    <h4>Support</h4>
                    <ul>
                      <li><Link to="/support">Support</Link></li>
                      <li><Link to="/contact">Contact Us</Link></li>
                      <li><Link to="/">Site feedback</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-lg-12">
                <div className="footer-copy-right text-center">
                  <p className="text-center text-md-end text-xl-start">
                    All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </footer>
  );
};

export default Footer;
