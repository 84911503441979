import Main from "../components/Main";

function Home() {
  return (
    <div >
      <Main/>
    </div>
  );
}

export default Home;
