import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import airTicket from "./../img/air-ticket.jpg"
import retail from "./../img/retail.jpg"
import travel from "./../img/travel.jpg"


const courses = [
  {
    title: 'Travel and Tourism',
    description: 'Explore the world of travel and tourism, and learn the skills needed to excel in this vibrant industry.',
    image: travel,
  },
  {
    title: 'Air Ticketing',
    description: 'Master the essentials of air ticketing and gain the expertise required to succeed in this dynamic field.',
    image: airTicket,
  },
  {
    title: 'Retail Management',
    description: 'Develop the knowledge and skills necessary for a successful career in retail management.',
    image: retail,
  },
];

const CoursesCard = () => {
  return (
    <div className='container'>
    <Grid container spacing={3}>
      {courses.map((course, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card>
            <CardMedia
              component="img"
              alt={course.title}
              height="240"
              image={course.image}
              title={course.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {course.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {course.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </div>
  );
};

export default CoursesCard;
