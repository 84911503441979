import React from 'react'

function MoreAboutUs() {
  return (
    <div>
      {/* About Law Start */}
      <div className="about-area section-padding2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-caption mb-50">
                                <div className="section-tittle mb-35">
                                    <span>More About Our Academy</span>
                                    <h2>Want to know more</h2>
                                </div>
                                <p>We have placed over 600+ students in top positions across aviation, hospitality, retail, and tourism. Our academy offers a range of courses including diploma, certificate, and fast-track programs designed to meet industry demands.</p>
                                <ul>
                                    <li><span className="flaticon-business"></span> Cutting-edge facilities</li>
                                    <li><span className="flaticon-communications-1"></span> Experienced trainers</li>
                                    <li><span className="flaticon-graduated"></span> Guaranteed job placements assistance</li>
                                    <li><span className="flaticon-tools-and-utensils"></span> Carrer Guidence</li>
                                </ul>
                                <a href="about.html" className="btn">More About Us</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <div className="about-font-img d-none d-lg-block">
                                    <img src="assets/img/gallery/about2.png" alt="About" />
                                </div>
                                <div className="about-back-img">
                                    <img src="assets/img/gallery/about1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About Law End */}

    </div>
  )
}

export default MoreAboutUs
